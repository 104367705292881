import { ref, FirebaseStorage } from "firebase/storage";
import { checkIfFileAlreadyInCloud } from "./checkIfFileAlreadyInCloud";
import { FILE_VERSION_REGEX } from "./FILE_VERSION_REGEX";

export const getCorrectFileVersion = async (
  filename: string,
  index = 1,
  storage: FirebaseStorage
): Promise<string> => {
  let newName = filename;
  const tryAgain = await checkIfFileAlreadyInCloud(
    ref(storage, `files/${newName}`)
  );
  if (tryAgain) {
    const regexRes = newName.match(FILE_VERSION_REGEX);
    if (regexRes && regexRes.length) {
      const version = regexRes[0];
      const vNum = Number(version.replace(".", "").slice(2));
      index = vNum;
    }
    let [name, ext] = newName.split(".");
    name = name.replace("_v" + index, "");
    index++;
    newName = name + "_v" + index + "." + ext;
    newName = await getCorrectFileVersion(newName, index, storage);
  }

  return newName;
};
