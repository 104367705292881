import { CuraWASM } from "cura-wasm";
import { stringifyOverrides } from "./stringifyOverrides";
import type { PrinterID } from "cura-wasm-definitions/dist/types";
import type { override } from "cura-wasm/dist/types";

export interface ISlicedFileData {
  definitionUsed: PrinterID | null;
  overridesUsed: override[];
  objUrl: string;
  blob: Blob;
  file: File;
}
export async function sliceSTL(
  originFileName: string,
  url: string,
  definition: PrinterID | null,
  overrides: override[],
  setProgress?: (p: number) => void
): Promise<ISlicedFileData> {
  const extruderOverrides: override[] = [
    { scope: "e0", key: "cool_fan_enabled", value: "false" },
    { scope: "e0", key: "cool_fan_full_at_height", value: "1" },
    { scope: "e0", key: "infill_before_walls", value: "false" },
    { scope: "e0", key: "infill_overlap", value: "40" },
    { scope: "e0", key: "infill_sparse_density", value: "20" },
    { scope: "e0", key: "layer_0_z_overlap", value: "0.15" },
    { scope: "e0", key: "material_print_temperature", value: "215" },
    { scope: "e0", key: "raft_airgap", value: "0.5" },
    { scope: "e0", key: "raft_margin", value: "8" },
    { scope: "e0", key: "raft_surface_layers", value: "3" },
    { scope: "e0", key: "retraction_amount", value: "1.5" },
    { scope: "e0", key: "retraction_extra_prime_amount", value: "0.25" },
    { scope: "e0", key: "retraction_speed", value: "50" },
    { scope: "e0", key: "skin_overlap", value: "20" },
    { scope: "e0", key: "speed_infill", value: "35" },
    { scope: "e0", key: "speed_layer_0", value: "15" },
    { scope: "e0", key: "speed_print", value: "35" },
    { scope: "e0", key: "speed_topbottom", value: "35" },
    { scope: "e0", key: "speed_travel", value: "80" },
    { scope: "e0", key: "speed_travel_layer_0", value: "15" },
    { scope: "e0", key: "speed_wall", value: "35" },
    { scope: "e0", key: "speed_wall_0", value: "35" },
    { scope: "e0", key: "support_bottom_distance", value: "0.3" },
    { scope: "e0", key: "support_infill_rate", value: "20" },
    { scope: "e0", key: "support_offset", value: "1" },
    { scope: "e0", key: "support_top_distance", value: "0.4" },
    { scope: "e0", key: "support_xy_distance", value: "0.7" },
    { scope: "e0", key: "support_xy_distance_overhang", value: "1" },
    { scope: "e0", key: "z_seam_corner", value: "z_seam_corner_none" },
    { scope: "e0", key: "z_seam_type", value: "sharpest_corner" },
    { scope: "", key: "material_diameter", value: "1.75" },
  ];

  const printerOverrides: override[] = [
    { scope: "", key: "adhesion_type", value: "none" },
    { scope: "", key: "layer_height", value: "0.25" },
    { scope: "", key: "material_bed_temperature", value: "60" },
    { scope: "", key: "machine_heated_bed", value: "true" },
    { scope: "", key: "retraction_combing", value: "noskin" },
    { scope: "", key: "speed_slowdown_layers", value: "1" },
    { scope: "", key: "support_enable", value: "false" },
    { scope: "", key: "center_object", value: "true" },
    { scope: "", key: "machine_width", value: "220" },
    { scope: "", key: "machine_depth", value: "220" },
    { scope: "", key: "machine_height", value: "300" },
  ];

  const extruderSettings = stringifyOverrides(extruderOverrides);
  const printerSettings = stringifyOverrides(printerOverrides);

  // ! Be careful, even extra spaces make the command not work, very sensitive.
  const command = `slice -j definitions/fdmprinter.def.json ${printerSettings}-e0 ${extruderSettings}-l Model.stl -o Model.gcode`;

  const slicer = new CuraWASM({ command });

  const res = await fetch(url);

  const stl = await res.arrayBuffer();

  if (setProgress)
    slicer.on("progress", (p: number) => {
      setProgress(p);
    });

  const { gcode } = await slicer.slice(stl, "stl");

  const blob = new Blob([gcode], { type: "text/plain" });

  const MyDate = new Date();
  var month = MyDate.getUTCMonth() + 1; //months from 1-12
  var day = MyDate.getUTCDate();
  var year = MyDate.getUTCFullYear();

  let newName = "";

  if (originFileName.includes(".stl"))
    newName = originFileName.replace(
      ".stl",
      `_cookie-${month}-${day}-${year}.gcode`
    );
  else if (originFileName.includes(".STL"))
    newName = originFileName.replace(
      ".STL",
      `_cookie-${month}-${day}-${year}.gcode`
    );

  const file = new File([blob], newName);

  const objUrl = URL.createObjectURL(blob);

  slicer.destroy();

  if (setProgress) setProgress(0);

  return {
    definitionUsed: definition,
    overridesUsed: overrides,
    objUrl,
    blob,
    file,
  };
}
