import ReactLoading from "react-loading";
import styles from "../styles/Uploading.module.css";

interface IUploadingProps {
  currentFile?: string;
  fileState: number;
}

export const Uploading: React.FC<IUploadingProps> = ({
  currentFile,
  fileState,
}) => {
  return (
    <div className={styles.root}>
      {/* <p className={styles.message}>Please refrain from closing your browser before the upload is complete</p> */}
      <p className={styles.label}>Uploading: </p>
      <p className={styles.file}>{currentFile ? currentFile : null}</p>
      <div className={styles.barContainer}>
        <div
          className={styles.bar}
          style={{
            width: fileState + "%",
          }}
        ></div>
      </div>
      <ReactLoading
        type="spinningBubbles"
        color="var(--primary-color)"
        height={"20px"}
        width={"20px"}
      />
    </div>
  );
};
