import { Nav } from "./Nav";
import { PageWrapper } from "./wrappers/PageWrapper";

export const NotAuthorized = () => {
  return (
    <div>
      <Nav />
      <PageWrapper>
        <h1 className="text-lg">
          ❌ User is not authorized to use this application
        </h1>
      </PageWrapper>
    </div>
  );
};
