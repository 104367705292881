import React, { useEffect, useRef } from "react";

interface Props {
  submitHandler?: () => void;
  email: string;
  emailOnChangeHandler: (e: React.ChangeEvent<HTMLInputElement>) => void;
  focus?: boolean
}

export const EmailForm = ({
  email,
  emailOnChangeHandler,
  focus = false
}: Props) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  useEffect(() => {
    if (focus) inputRef.current?.focus();
  }, [focus]);
  return (
    <div
      className="flex items-center"
      style={{position: 'relative'}}
    >
      <div className="field" style={{width: '100%', justifyContent: 'space-between'}}>
        <label htmlFor="email" className="label">
          Email:
        </label>
        <input
          ref={inputRef}
          onChange={emailOnChangeHandler}
          value={email}
          type="text"
          className="text-input"
          required
        />
      </div>
      {/* <div style={{position: 'absolute', top: 0, right: -40}}>
        <button type="submit" hidden></button>
        <BsArrowRightShort
          tabIndex={0}
          onClick={submitHandler}
          className="cursor-pointer text-lg"
        />
      </div> */}
    </div>
  );
};
