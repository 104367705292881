import { StorageReference, getMetadata } from "firebase/storage";

export async function checkIfFileAlreadyInCloud(fileRef: StorageReference) {
  let exists = false;
  try {
    exists = !!(await getMetadata(fileRef));
  } catch (err) {
    console.log("File doesn't exist");
  }
  return exists;
}
