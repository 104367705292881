interface Props {
  message: string;
}

export const MessageToClient = ({ message }: Props) => {
  return (
    <div
      style={{
        opacity: 0,
        transform: "scale(.3)",
        width: "100%",
        animation: "popup .8s ease .5s forwards",
      }}
    >
      <label
        htmlFor="message-to-client"
        style={{
          fontSize: ".8rem",
          fontWeight: 800,
          color: "red",
        }}
      >
        Message from Slant 3D
      </label>
      <p
        style={{
          fontSize: "1rem",
          color: "#000000",
          marginBottom: "3rem",
          fontWeight: 500,
          padding: "1rem 0",
          width: "100%",
          border: "1px solid #9cbece",
          background: "#d8e8fa",
          borderRadius: ".6rem",
        }}
      >
        {message}
      </p>
    </div>
  );
};
