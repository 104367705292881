import { Link } from "react-router-dom";
import { SlantSVG } from "./svg/SlantSVG";

export const Nav = () => {
  return (
    <div style={{ position: "fixed", top: 0, left: 0, padding: "1rem" }}>
      <Link to="/">
        <SlantSVG />
      </Link>
    </div>
  );
};
