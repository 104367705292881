import classNames from "classnames";
import styles from '../styles/SignUp.module.css'
import { Nav } from "./Nav";
import { EmailForm } from "./EmailForm";
import { PasswordForm } from "./PasswordForm";
import { useHistory } from "react-router-dom";
import { PageWrapper } from "./wrappers/PageWrapper";
import { Divider } from "./Divider";
import { AnimateIn } from "./animations/AnimateIn";
import { removeFormEvent } from "../utils/removeFormEvent";

interface ISignUpProps {
    signInEmail: string;
    signInEmailOnChangeHandler: (e: React.ChangeEvent<HTMLInputElement>) => void;
    signInPassword: string;
    signInPasswordOnChangeHandler: (e: React.ChangeEvent<HTMLInputElement>) => void;
    signUpEmail: string;
    signUpEmailOnChangeHandler: (e: React.ChangeEvent<HTMLInputElement>) => void;
    signUpPassword: string;
    signUpPasswordOnChangeHandler: (e: React.ChangeEvent<HTMLInputElement>) => void;
    confirmPassword: string;
    confirmPasswordOnChangeHandler: (e: React.ChangeEvent<HTMLInputElement>) => void;
    currentSection: "email" | "password";
    setCurrentSection: React.Dispatch<"email" | "password">;
    googleAuthSignUpHandler: (history?: any) => void;
    handleCustomSignUp: (email: string, password: string, history: any) => void;
    resetPasswordHandler: () => void;
    handleCustomSignIn: (email: string, password: string, history: any) => void;
    signInError: {type: 'wrong-password' | 'too-many-attempts' | 'account-disabled' | 'user-not-found' | 'none', msg: string};
    signUpError: {type: 'weak-password' | 'passwords-dont-match' | 'email-in-use' | 'none', msg: string}
}

export const SignIn = ({
    signInEmail,
    signInEmailOnChangeHandler,
    signInPassword, 
    signInPasswordOnChangeHandler,
    signUpEmail,
    signUpEmailOnChangeHandler,
    signUpPassword,
    signUpPasswordOnChangeHandler,
    confirmPassword,
    confirmPasswordOnChangeHandler,
    handleCustomSignUp,
    handleCustomSignIn,
    resetPasswordHandler,
    signInError,
    signUpError
}: ISignUpProps) => {
    const history = useHistory();
      return (
        <div style={{ maxWidth: "100vw", overflow: "hidden" }}>
          <Nav />
          <AnimateIn>
            <PageWrapper>
                <form
                  className={classNames(styles.wrapper)}
                  onSubmit={(e) => removeFormEvent(e, () => handleCustomSignUp(signUpEmail, confirmPassword, history))}
                >
                  <div style={{textAlign: 'left'}}>
                    <h4 className="ml-3 mb-15">Sign Up</h4>
                  </div>
                  <div className={styles.boxWrapper}>
                    <EmailForm
                      email={signUpEmail}
                      emailOnChangeHandler={signUpEmailOnChangeHandler}
                      focus
                    />
                    <div className="mb-10"></div>
                    <PasswordForm
                      password={signUpPassword}
                      passwordOnChangeHandler={signUpPasswordOnChangeHandler}
                    />
                    <div className="mb-10"></div>
                    <PasswordForm
                      password={confirmPassword}
                      passwordOnChangeHandler={confirmPasswordOnChangeHandler}
                      confirm
                    />
                    {signUpError.type !== "none" ? (
                      <p className="text-sm mt-3" style={{ color: "red" }}>
                        {signUpError.msg}
                      </p>
                    ) : null}
                    <button type="submit" className="button mt-30">Sign Up</button>
                  </div>
                  {/* <section
                    className="flex justify-center flex-center"
                    style={{ position: "relative", transform: "translateY(10vh)" }}
                  >
                    <button
                      className="button google"
                      onClick={() => googleAuthSignUpHandler(history)}
                    >
                      <img
                        src="/google-icon.png"
                        alt="google displayer for oauth sign in"
                        height="20px"
                        width="20px"
                      />
                      Sign In With Google
                    </button>
                  </section> */}
                </form>
                <Divider />
                <form className={styles.wrapper} onSubmit={(e) => removeFormEvent(e, () => handleCustomSignIn(signInEmail, signInPassword, history))}>
                  <div style={{textAlign: 'left'}}>
                    <h4 className="ml-3 mb-15">Log In</h4>
                  </div>
                  <div className={styles.boxWrapper}>
                    <EmailForm
                      email={signInEmail}
                      emailOnChangeHandler={signInEmailOnChangeHandler}
                    />
                    <div className="mb-10"></div>
                    <PasswordForm
                      password={signInPassword}
                      passwordOnChangeHandler={signInPasswordOnChangeHandler}
                    />
                    <div>
                      <p className="text-sm mt-5" style={{color: 'blue', cursor: 'pointer'}} onClick={resetPasswordHandler}>Reset Password</p>
                    </div>
                    {signInError.type !== "none" ? (
                      <p className="text-sm mt-3" style={{ color: "red" }}>
                        {signInError.msg}
                      </p>
                    ) : null}
                    <button type="submit" className="button mt-30">Log In</button>
                  </div>
                </form>
            </PageWrapper>
          </AnimateIn>
        </div>
      );
}
