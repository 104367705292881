export interface ISVGProps {
  height?: string;
  width?: string;
  fill?: string;
}

interface Props extends ISVGProps {}

export const SlantSVG = ({
  height = "42",
  width = "140",
  fill = "black",
}: Props) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 140 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M40.8148 0L66.2226 0.267516V6.15287H44.3286L40.8148 0Z"
        fill={fill}
      />
      <path
        d="M45.1395 41.1975L59.5339 12.0382L65.6822 12.0382L54.0593 36.1147L45.1395 41.1975Z"
        fill={fill}
      />
      <path
        d="M117.579 41.7324L127.039 17.656H132.986L124.066 41.7324H117.579Z"
        fill={fill}
      />
      <path
        d="M69.1959 41.4867L82.1701 12.3057V26.4841L75.4882 41.4868L69.1959 41.4867Z"
        fill={fill}
      />
      <path
        d="M88.9314 41.7542L101.091 12.0382L101.091 27.0191L95.2237 41.7543L88.9314 41.7542Z"
        fill={fill}
      />
      <path
        d="M106.497 26.2166L111.362 12.0382H117.849L105.686 41.1975L106.497 26.2166Z"
        fill={fill}
      />
      <path
        d="M81.0889 42L82.1701 12.5732H88.3869L87.0354 42H81.0889Z"
        fill={fill}
      />
      <path
        d="M100.01 41.465L101.091 12.0382L107.308 12.0382L105.956 41.465H100.01Z"
        fill={fill}
      />
      <path
        d="M53.1262 36.1179L67.045 36.1234L67.0595 41.4138L44.8837 41.4736L53.1262 36.1179Z"
        fill={fill}
      />
      <path
        d="M120.552 12.6417L139.985 12.5893L140 17.8798L120.567 17.9321L120.552 12.6417Z"
        fill={fill}
      />
      <path d="M0 35.5796H21.0831L25.1376 40.9299H0V35.5796Z" fill={fill} />
      <path
        d="M21.0831 35.5796L40.8148 0L44.3286 6.15287L24.8673 40.9299L21.0831 35.5796Z"
        fill={fill}
      />
    </svg>
  );
};
