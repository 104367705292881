import { Link } from "react-router-dom";
import styles from "../styles/FrontPage.module.css";
import { AnimateIn } from "./animations/AnimateIn";
import { PageWrapper } from "./wrappers/PageWrapper";

export const FrontPage = () => {
  return (
    <PageWrapper>
      <AnimateIn>
        <img
          style={{ marginBottom: ".8rem" }}
          src="/slant-3d-logo.jpg"
          alt="Slant 3D's logo"
        />
        <h3 className={styles.subtitle}>
          Print On Demand File Submission
        </h3>
        <Link to="/sign-in" className={styles.button}>
          Sign In
        </Link>
      </AnimateIn>
    </PageWrapper>
  );
};
