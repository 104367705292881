import { createContext } from "react";
import { IFileSystemItem } from "../types/TFileSystemItem";

export type TAutoSliceVariant = "cookie-cutters";

export interface IUploadingFile {
  file: File;
  isSliced: boolean;
  folder: IFileSystemItem;
  autoSlice?: TAutoSliceVariant;
  overwrite: boolean;
}

export interface IUploadingFileContext {
  isUploading: boolean;
  setIsUploading: (T: boolean) => void;
  file: string;
  setFile: (T: string) => void;
  fileState: number;
  setFileState: (T: number) => void;
  filesToUpload: IUploadingFile[];
  setFilesToUpload: (T: IUploadingFile[]) => void;
}

export const UploadingFileContext = createContext<IUploadingFileContext>({
  isUploading: false,
  setIsUploading: (T: boolean) => {},
  file: "",
  setFile: (T: string) => {},
  fileState: 0,
  setFileState: (T: number) => {},
  filesToUpload: [],
  setFilesToUpload: () => {},
});
