import classNames from "classnames";
import React from "react";
import styles from "../styles/Notify.module.css";
interface Props {
  isOpen: boolean;
  closeHandler: () => void;
}

export const Notify: React.FC<Props> = ({ children, isOpen, closeHandler }) => {
  return (
    <div className={classNames(styles.root, { [styles.visible]: isOpen })}>
      <div className={styles.content}>
        {children}
        <div onClick={closeHandler} className={styles.closeBtn}>
          x
        </div>
      </div>
      <div onClick={closeHandler} className={styles.underlay} />
    </div>
  );
};
