import classNames from "classnames";
import React, { useContext } from "react";
import Dropzone from "react-dropzone";
import FirestoreContext from "../context/FirestoreContext";
import styles from "../styles/Drop.module.css";

interface DropProps {
  handleDrop: (files: File[]) => void;
}

export const Drop: React.FC<DropProps> = ({ handleDrop }) => {
  const { db } = useContext(FirestoreContext);
  if (!db) return null;
  const onDropHandler = async (e: React.DragEvent) => {
    const files = e.dataTransfer.files;
    if (!files) return;

    let arr: File[] = [];
    for (let i = 0; i < files.length; i++) {
      arr.push(files[i]);
    }

    handleDrop(arr);
  };

  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const files = e.target.files;
    if (!files) return;
    let arr: File[] = [];

    for (let i = 0; i < files.length; i++) {
      arr.push(files[i]);
    }

    handleDrop(arr);
  };

  return (
    <div className={styles.contentWrapper}>
      <Dropzone>
        {({ getRootProps, getInputProps, isDragActive }) => (
          <>
            <div
              className={classNames(styles.drop, {
                [styles.accept]: isDragActive,
              })}
              {...getRootProps({
                onDrop: onDropHandler,
                onChange: onChangeHandler,
              })}
            >
              <input {...getInputProps({ multiple: true })} />
              <p>
                {isDragActive ? "Just let go" : "Click Here or Drag 'n Drop"}
              </p>
              {!isDragActive ? (
                <small>
                  <em className={styles.smallTip}>
                    Only STL or GCODE files are accepted
                  </em>
                </small>
              ) : null}
            </div>
          </>
        )}
      </Dropzone>
    </div>
  );
};
