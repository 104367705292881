import { createContext } from "react";
import { Timestamp, Firestore } from "firebase/app/dist/firestore";
import { Functions } from "firebase/app/dist/functions";

export interface ITimestamp extends Timestamp {}

interface MyData {
  db: Firestore | null;
  functions: Functions | null;
}

export default createContext<MyData>({ db: null, functions: null });
