import { useEffect } from "react";
import { Firestore, collection } from "firebase/firestore";
import { ref, getStorage } from "firebase/storage";
import {
  IUploadingFile,
  UploadingFileContext,
} from "../context/UploadingFileContext";
import { sliceSTL } from "../utils/sliceSTL";
import { getDownloadURLFromCloud } from "../utils/getDownloadURLFromCloud";
import { checkIfFileAlreadyInCloud } from "../utils/checkIfFileAlreadyInCloud";
import { TUser } from "../types/TUser";
import { getCorrectFileVersion } from "../utils/getCorrectFileVersion";

interface Props {
  isUploading: boolean;
  setIsUploading: React.Dispatch<boolean>;
  file: string;
  setFile: React.Dispatch<string>;
  fileState: number;
  setFileState: React.Dispatch<number>;
  filesToUpload: IUploadingFile[];
  setFilesToUpload: React.Dispatch<IUploadingFile[]>;
  db: Firestore;
  currentUser?: TUser;
}

export const UploadingFileProvider: React.FC<Props> = ({
  isUploading,
  setIsUploading,
  file,
  setFile,
  fileState,
  setFileState,
  filesToUpload,
  setFilesToUpload,
  db,
  children,
  currentUser,
}) => {
  useEffect(() => {
    if (filesToUpload.length && !isUploading) {
      (async function () {
        setIsUploading(true);
        const copy = [...filesToUpload];
        let currentFile = copy.shift();
        if (!currentFile) {
          setIsUploading(false);
          return;
        }

        let ext = currentFile.file.name.split(".").pop();

        if (ext === "GCODE" || ext === "STL") {
          currentFile.file = new File(
            [currentFile.file],
            currentFile.file.name.replace(ext, ext.toLowerCase())
          );
          ext = ext.toLowerCase();
        }

        if (ext === "gcode" || ext === "stl") {
          // * Do nothing
        } else {
          alert(
            "We only accept GCODE and STL files. " +
              currentFile.file.name +
              " will not be uploaded."
          );
          setIsUploading(false);
          return;
        }

        const storage = getStorage();

        const fileAlreadyInCloud = await checkIfFileAlreadyInCloud(
          ref(storage, `files/${currentFile.file.name}`)
        );

        if (fileAlreadyInCloud) {
          const newName = await getCorrectFileVersion(
            currentFile.file.name,
            undefined,
            storage
          );
          currentFile.file = new File([currentFile.file], newName);
        }

        setFile(currentFile.file.name);

        const fileNeedsUploaded = true;

        await import("../utils/uploadFileToCloud").then(async (obj) => {
          if (!currentFile) return;
          await obj.uploadFileToCloud(
            currentFile.file,
            ref(storage),
            currentFile.folder.id,
            setFileState,
            currentFile.isSliced,
            collection(db, "fileSystem"),
            fileNeedsUploaded,
            db,
            { owner: currentUser?.email }
          )
        })

        if (
          currentFile.autoSlice === "cookie-cutters" &&
          currentFile.file.name.includes(".stl")
        ) {
          setFile("Slicing " + currentFile.file.name);
          const { file } = await sliceSTL(
            currentFile.file.name,
            await getDownloadURLFromCloud(currentFile.file.name),
            "fdmprinter",
            [],
            setFileState
          );
          const newFile: IUploadingFile = {
            file,
            isSliced: false,
            folder: currentFile.folder,
            overwrite: false,
          };
          setFile(newFile.file.name);

          await import("../utils/uploadFileToCloud").then(async (fn) => {
            await fn.uploadFileToCloud(
              newFile.file,
              ref(storage),
              newFile.folder.id,
              setFileState,
              newFile.isSliced,
              collection(db, "fileSystem"),
              true,
              db,
              { owner: currentUser?.email }
            );
          })
          
        }
        setFile("");
        setIsUploading(false);
        if (filesToUpload.length === 1) alert("Yay submission fully complete!");
      })();
      const copy = [...filesToUpload];
      copy.shift();
      setFilesToUpload(copy);
    }
  }, [
    filesToUpload,
    isUploading,
    db,
    setFile,
    setFileState,
    setFilesToUpload,
    setIsUploading,
    currentUser?.email,
  ]);
  return (
    <UploadingFileContext.Provider
      value={{
        isUploading,
        setIsUploading,
        file,
        setFile,
        fileState,
        setFileState,
        filesToUpload,
        setFilesToUpload,
      }}
    >
      {children}
    </UploadingFileContext.Provider>
  );
};
