import React, { useCallback, useContext, useEffect, useState } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  signOut,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  createUserWithEmailAndPassword
} from "firebase/auth";
import { collection, where, getDocs, query} from "firebase/firestore";
import { SignIn } from "./components/SignIn";
import { TUser } from "./types/TUser";
import "./App.css";
import { Notify } from "./components/Notify";
import { FrontPage } from "./components/FrontPage";
import FirestoreContext from "./context/FirestoreContext";
import { AppContent } from "./components/AppContent";
import { NotAuthorized } from "./components/NotAuthorized";
import { addServiceWorkerListener } from "./serviceWorkerRegistration";

function App() {
  const [user, setUser] = useState<TUser | null>(null);
  const [passwordResetLinkSent, setPasswordResetLinkSent] = useState(false)
  const [notifySignedOutOpen, setNotifySignedOutOpen] = useState(false);
  const [signUpError, setSignUpError] = useState<{type: 'weak-password' | 'passwords-dont-match' | 'email-in-use' | 'none', msg: string}>({
    type: 'none',
    msg: ''
  })
  const [signInError, setSignInError] = useState<{type: 'wrong-password' | 'too-many-attempts' | 'account-disabled' | 'user-not-found' | 'none', msg: string}>({
    type: 'none',
    msg: ''
  })
  const [signInPassword, setSignInPassword] = useState("");
  const [signUpPassword, setSignUpPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState("");
  const [signInEmail, setSignInEmail] = useState("");
  const [signUpEmail, setSignUpEmail] = useState('')
  const [signUpSection, setSignUpSection] = useState<"email" | "password">("email");
  const [thereIsAnUpdate, setThereIsAnUpdate] = useState(false)
  const [couldntSendResetLink, setCouldntSendResetLink] = useState({active: false, msg: ''})
  
  const { db } = useContext(FirestoreContext);

  const getAuthedUser = useCallback(async (email: string) => {
    if (!db) throw new Error("Firebase isn't setup");
    const authedUsersRef = collection(db, "authedClients")
    const authedUsersQuery = query(authedUsersRef, where("email", "==", email))
    const authedUsersSnap = await getDocs(authedUsersQuery)
    return authedUsersSnap.empty ? null : {...authedUsersSnap.docs[0].data(), id: authedUsersSnap.docs[0].id} as TUser
  }, [db])

  useEffect(() => {
    addServiceWorkerListener({onUpdate: () => {
      setThereIsAnUpdate(true)
    }})

    if (!db) return;
    const auth = getAuth()
    auth.onAuthStateChanged(async (user) => {
      if (!user || !user.email) return;
      const authedUser = await getAuthedUser(user.email)
      setUser(authedUser)
      if (authedUser) {
        // * It will go into an infinite loop if you don't check 
        // * the paths. 
        if (window.location.pathname !== "/file-submission")
          window.location.replace("/file-submission")
      } else {
        if (window.location.pathname !== "/not-authorized")
          window.location.replace("/not-authorized")
      }
    })
  }, [db, getAuthedUser])

  if (!db) return null;

  const clearSignInInfo = () => {
    setSignInEmail('')
    setSignInPassword('')
    setSignInError({type: 'none', msg: ""})
    setSignUpEmail('')
    setSignUpPassword('')
    setSignUpError({type: 'none', msg: ""})
    setConfirmPassword("")
  }

  const sendEmailResetLink = () => {
    const auth = getAuth()

    sendPasswordResetEmail(auth, signInEmail)
      .then(() => {
        console.log("Password email sent!")
        setPasswordResetLinkSent(true)
      }).catch((err) => {
        let msg = "Couldn't send reset link."
        if (err.message.includes("auth/user-not-found")) msg += "Couldn't find a user with that email."
        else if (err.message.includes("auth/missing-email")) msg += "Please type out your email in the 'Log In' form."
        else if (err.message.includes("auth/invalid-email")) msg += "That is not a valid email. Please type out your full email in the 'Log In' form. (eg. email@example.com)"
        setCouldntSendResetLink({active: true, msg})
      })
  }

  const signOutHandler = () => {
    const auth = getAuth();
    signOut(auth)
      .then(() => {
        setUser(null);
        setNotifySignedOutOpen(true);
        window.localStorage.removeItem("firebaseIDToken")
      })
      .catch(() => {
        alert("There was an error signing out, please try again.");
        return;
      });
  };

  const resetPassword = () => setSignInPassword("");

  const googleAuthSignInHandler = (history?: any) => {
    const provider = new GoogleAuthProvider();

    const auth = getAuth();

    signInWithPopup(auth, provider)
      .then(async (userCred) => {
        const user = userCred.user;
        if (!user || !user.email) return;
        const authedUser = await getAuthedUser(user.email)
        setUser(authedUser)
        if (authedUser) {
          history.push("/file-submission")
        } else {
          history.push("/not-authorized")
        }
      })
      .catch(() => {
        setUser(null);
        clearSignInInfo()
        history.push("/not-authorized")
      });
  };

  // * Custom sign in aka you have input your email and password manually
  // * instead of using an oauth sign in
  const handleCustomSignIn = (
    email: string,
    password: string,
    history: any
  ) => {
    const auth = getAuth();
    signInWithEmailAndPassword(auth, email, password)
      .then(async (userCred) => {
        console.log("Signed in successfully")
        clearSignInInfo()
        const user = userCred.user;
        if (!user || !user.email) return;
        const authedUser = await getAuthedUser(user.email)
        setUser(authedUser)
        if (authedUser) {
          history.push("/file-submission")
        } else {
          history.push("/not-authorized")
        }
      })
      .catch((err: Error) => {
        if (typeof err !== "string") {
          console.error("msg: ", err.message)
          if (err.message.includes("wrong-password")) {
            setSignInError({type: 'wrong-password', msg: "Wrong password"})
            resetPassword();
          } else if (err.message.includes("user-not-found")) {
            setSignInError({type: 'user-not-found', msg: "Couldn't find a user with this email"})
          } else if (err.message.includes("too-many-requests")) {
            setSignInError({type: 'account-disabled', msg: "Your account has been disabled"})
          }
        }
      });
  };
 // * Custom sign up aka you have input your email and password manually
  // * instead of using an oauth
  const handleCustomSignUp = (
    email: string,
    _password: string,
    history: any
  ) => {
    if (_password !== signUpPassword) {
      setSignUpError({type: "passwords-dont-match", msg: "Passwords dont match"})
      return
    }
    const auth = getAuth()
    createUserWithEmailAndPassword(auth, email, _password).then(async (userCred) => {
      // * Signed in...
      const user = userCred.user;
      if (!user || !user.email) return;
      const authedUser = await getAuthedUser(user.email)
      setUser(authedUser)
      if (authedUser) {
        history.push("/file-submission")
      } else {
        history.push("/not-authorized")
      }
    }).catch(err => {
      // console.error(err.code, err.message)
      if (err.code === "auth/email-already-in-use") {
        setSignUpError({type: 'email-in-use', msg: "The email you're using is already in use"})
      } else if (err.code === "auth/weak-password") {
        const msgWithoutFirebase = err.message.replace("Firebase: ", "")
        setSignUpError({type: 'weak-password', msg: msgWithoutFirebase})
      }
    })
  }

  const signInEmailOnChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) =>
    setSignInEmail(e.target.value);
  const signInPasswordOnChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) =>
    setSignInPassword(e.target.value);
  const signUpEmailOnChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSignUpEmail(e.target.value)
  }
  const signUpPasswordOnChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSignUpPassword(e.target.value)
  }
  const confirmPasswordOnChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setConfirmPassword(e.target.value)
  }

  return (
    <div className="App">
      <Router>
        <Switch>
          <Route path="/not-authorized" component={NotAuthorized} />
          {user ? (
            <Route
              path="/file-submission"
              render={() => (
                <AppContent user={user} signOutHandler={signOutHandler} />
              )}
            />
          ) : null}
          <Route
            path="/sign-in"
            render={() => (
              <SignIn
                signInEmail={signInEmail}
                signInPassword={signInPassword}
                signUpEmail={signUpEmail}
                signUpPassword={signUpPassword}
                confirmPassword={confirmPassword}
                confirmPasswordOnChangeHandler={confirmPasswordOnChangeHandler}
                signInEmailOnChangeHandler={signInEmailOnChangeHandler}
                signInPasswordOnChangeHandler={signInPasswordOnChangeHandler}
                signUpEmailOnChangeHandler={signUpEmailOnChangeHandler}
                signUpPasswordOnChangeHandler={signUpPasswordOnChangeHandler}
                currentSection={signUpSection}
                setCurrentSection={setSignUpSection}
                resetPasswordHandler={sendEmailResetLink}
                googleAuthSignUpHandler={googleAuthSignInHandler}
                handleCustomSignUp={handleCustomSignUp}
                handleCustomSignIn={handleCustomSignIn}
                signInError={signInError}
                signUpError={signUpError}
              />
            )}
          />

          <Route path="/" component={FrontPage} />
        </Switch>
      </Router>
      {notifySignedOutOpen ? (
        <Notify
          isOpen={notifySignedOutOpen}
          closeHandler={() => setNotifySignedOutOpen(false)}
        >
          <p style={{ fontSize: "1.3rem" }}>
            You have successfully signed out ✔️ 
          </p>
        </Notify>
      ) : null}
      {passwordResetLinkSent ? (
        <Notify
          isOpen={passwordResetLinkSent}
          closeHandler={() => setPasswordResetLinkSent(false)}
        >
          <p style={{ fontSize: "1.3rem" }}>
          Link to reset your password was sent to your email! ✔️ 
          </p>
        </Notify>
      ): null}
      {thereIsAnUpdate ? (
        <Notify isOpen={thereIsAnUpdate} closeHandler={() => setThereIsAnUpdate(false)}>
          <p style={{ fontSize: "1.3rem" }}>
              There is an update for this application. The update will be install once all 
              tabs with this application runnning are closed!
          </p>
        </Notify> 
      ) : null}
      {couldntSendResetLink.active ? (
        <Notify isOpen={couldntSendResetLink.active} closeHandler={() => setCouldntSendResetLink({active: false, msg: ''})}>
          <p style={{ fontSize: "1.3rem" }}>
              {couldntSendResetLink.msg}
          </p>
        </Notify> 
      ) : null}
    </div>
  );
}

export default App;
