export const PageWrapper: React.FC = ({ children }) => {
  return (
    <div
      className="flex justify-center items-center flex-col max-w-large mx-auto"
      style={{
        color: "var(--font-color)",
        height: "100vh",
        maxWidth: "var(--screen-small)",
      }}
    >
      {children}
    </div>
  );
};
