import React from "react";
import styles from "../../styles/AnimateIn.module.css";

interface IAnimationProps {
  classes?: string;
}

export const AnimateIn: React.FC<IAnimationProps> = ({
  children,
  classes = "",
}) => {
  return <div className={`${styles.root} ${classes}`}>{children}</div>;
};
