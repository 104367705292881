import React, { useEffect, useRef } from "react";
import { BsArrowRightShort } from "react-icons/bs";

interface Props {
  submitHandler?: () => void;
  password: string;
  passwordOnChangeHandler: (e: React.ChangeEvent<HTMLInputElement>) => void;
  focus?: boolean;
  confirm?: boolean;
}

export const PasswordForm = ({
  submitHandler,
  password,
  passwordOnChangeHandler,
  focus = false,
  confirm
}: Props) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  useEffect(() => {
    if (focus) inputRef.current?.focus();
  }, [focus]);
  return (
    <div
      className="flex items-center"
      style={{position: 'relative'}}
    >
      <div className="field" style={{justifyContent: 'space-between', width: '100%'}}>
        <label htmlFor="password" className="label">
          {confirm ? "Confirm" : ""} Password:
        </label>
        <input
          ref={inputRef}
          onChange={passwordOnChangeHandler}
          value={password}
          type="password"
          className="text-input"
          required
        />
      </div>
      {submitHandler ? (
        <div style={{
          position: 'absolute', 
          right: -40,
          top: 0
        }}>
          <button type="submit" hidden></button>
          <BsArrowRightShort
            tabIndex={0}
            onClick={submitHandler}
            className="cursor-pointer text-lg"
          />
        </div>
      ): null}
    </div>
  );
};
