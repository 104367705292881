import ReactLoading from "react-loading";
import styles from "../styles/PageLoading.module.css";

export const PageLoading = () => {
  return (
    <div className={styles.root}>
      <ReactLoading
        type="spinningBubbles"
        color="var(--primary-color)"
        height={"200px"}
        width={"200px"}
      />
    </div>
  );
};
