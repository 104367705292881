import { useContext, useEffect, useState } from "react";
import {
  getDoc,
  doc,
  getFirestore,
  setDoc,
  Timestamp,
} from "firebase/firestore";
import FirestoreContext from "../context/FirestoreContext";
import { IUploadingFile } from "../context/UploadingFileContext";
import { IFileSystemItem } from "../types/TFileSystemItem";
import { TUser } from "../types/TUser";
import { Drop } from "./Drop";
import { PageLoading } from "./PageLoading";
import { UploadingFileProvider } from "../providers/UploadingFileProvider";
import { Uploading } from "./Uploading";
import styles from "../styles/AppContent.module.css";
import { MessageToClient } from "./MessageToClient";
import { PageWrapper } from "./wrappers/PageWrapper";
import { AnimateIn } from "./animations/AnimateIn";

interface Props {
  user: TUser;
  signOutHandler: () => void;
}

export const AppContent = ({ user, signOutHandler }: Props) => {
  const [folder, setFolder] = useState<IFileSystemItem | null>(null);
  const [isUploading, setIsUploading] = useState(false);
  const [currentFileUploading, setCurrentFileUploading] = useState("");
  const [fileUploadingState, setFileUploadingState] = useState(0);
  const [filesToUpload, setFilesToUpload] = useState<IUploadingFile[]>([]);
  const { db } = useContext(FirestoreContext);

  useEffect(() => {
    const getData = async () => {
      if (!db) return;

      const d = await getDoc(doc(db, `fileSystem/${user.folderId}`));
      const folder = { ...d.data, id: d.id } as IFileSystemItem;
      setFolder(folder);
    };
    getData();
  }, [db, user.folderId]);

  if (!folder) return <PageLoading />;

  const handleDrop = (files: File[]) => {
    if (!db) return;

    if (filesToUpload.length + files.length > 50) {
      alert(
        "You can't send over 50 files at a time. Please wait for the queue to finish uploading or add files in at lower volumes."
      );
      return;
    }

    const date = new Date(Date.now());
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const fDoc = doc(db, `fileSystem/${folder.id}-${month}${day}${year}`);

    console.log(`${month}-${day}-${year}`);

    getDoc(fDoc).then(async (doc) => {
      if (!doc.exists())
        setDoc(fDoc, {
          name: `${month}-${day}-${year}`,
          parentId: folder.id,
          isFolder: true,
          isSliced: false,
          dateAdded: Timestamp.fromDate(date),
          owner: user.email,
        }).then(() => {
          getDoc(fDoc).then((doc) => {
            const newFiles: IUploadingFile[] = files.map((file) => {
              return {
                file,
                isSliced: false,
                folder: { ...doc.data(), id: doc.id } as IFileSystemItem,
                overwrite: false,
              };
            });

            setFilesToUpload([...filesToUpload, ...newFiles]);
          });
        });
      else {
        const newFiles: IUploadingFile[] = files.map((file) => {
          return {
            file,
            isSliced: false,
            folder: { ...doc.data(), id: doc.id } as IFileSystemItem,
            overwrite: false,
          };
        });
        setFilesToUpload([...filesToUpload, ...newFiles]);
      }
    });
  };

  return (
    <UploadingFileProvider
      isUploading={isUploading}
      setIsUploading={setIsUploading}
      file={currentFileUploading}
      setFile={setCurrentFileUploading}
      fileState={fileUploadingState}
      setFileState={setFileUploadingState}
      filesToUpload={filesToUpload}
      setFilesToUpload={setFilesToUpload}
      db={getFirestore()}
      currentUser={user}
    >
      <PageWrapper>
        <AnimateIn classes="">
          <h2 style={{ marginBottom: "3rem", fontWeight: 500 }}>
            Hello 👋 {user.name || user.email}
          </h2>
          <p
            style={{
              fontSize: "1rem",
              color: "var(--font-color)",
              marginBottom: "3rem",
              fontWeight: 500,
            }}
          >
            Please submit your files to Slant 3D by using our drag 'n drop below
          </p>
          {user.messageToClient ? (
            <MessageToClient message={user.messageToClient} />
          ) : null}
          <div>
            <Drop handleDrop={handleDrop} />
          </div>
        </AnimateIn>
      </PageWrapper>
      {isUploading ? (
        <Uploading
          currentFile={currentFileUploading}
          fileState={fileUploadingState}
        />
      ) : null}
      <button onClick={signOutHandler} className={styles.signOutBtn}>
        Sign Out
      </button>
      <div style={{ position: "fixed", top: 0, right: 0, padding: ".5rem" }}>
        <p style={{ fontSize: ".8rem" }}>
          Files in queue: {filesToUpload.length}
        </p>
      </div>
    </UploadingFileProvider>
  );
};
