import { useEffect, useState } from "react";

import FirestoreContext from "../context/FirestoreContext";
import { getFunctions, Functions } from "firebase/functions";
import { getFirestore, Firestore } from "firebase/firestore";
import { initalizeFirebase } from "../initializeFirebase";

import { PageLoading } from "../components/PageLoading";

export const FirebaseProvider: React.FC = ({ children }) => {
  const [db, setDb] = useState<Firestore | null>(null);
  const [functions, setFunctions] = useState<Functions | null>(null);

  useEffect(() => {
    const app = initalizeFirebase();
    const mydb = getFirestore(app);
    const myFunctions = getFunctions(app);
    setDb(mydb);
    setFunctions(myFunctions);
  }, []);

  if (!db) return <PageLoading />;
  if (!functions) return <PageLoading />;

  return (
    <FirestoreContext.Provider value={{ db, functions }}>
      {children}
    </FirestoreContext.Provider>
  );
};
