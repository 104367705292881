import { initializeApp } from "firebase/app";
// import { connectFunctionsEmulator, getFunctions } from "firebase/functions";
// import { connectStorageEmulator, getStorage } from "firebase/storage";
// import { connectFirestoreEmulator, getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

// Initialize Firebase
export const initalizeFirebase = () => {
  const app = initializeApp(firebaseConfig);
  // connectFunctionsEmulator(getFunctions(), "localhost", 5001);
  // connectStorageEmulator(getStorage(), "localhost", 9199);
  // connectFirestoreEmulator(getFirestore(), "localhost", 8080);
  return app;
};
